@import "@/styles/var.scss";
@import "@/styles/mixin.scss";

.skin15 {
  height: 100%;
  background-color: #f4f5f7;
  line-height: normal;

  .header {
    margin: 16px;
    background-color: #fff;
    border-radius: 8px;
    padding: 16px 12px;

    .examineBox{
      padding: 12px;
      background-color: #F8F8F8;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .examineTitle{
        font-weight: 700;
        font-size: 14px;
        color: var(--color-primary);
        margin-bottom: 16px;
      }
      .examineIconsBox{
        width: 100%;
        display: flex;
        .examineItem{
          flex: 1;
          display: flex;
          flex-direction: column;
          align-items: center;
          &>span {
            font-size: 10px;
            color: #BFBFBF;
          }
          .examineIcon{
            position: relative;
            height: 32px;
            width: 32px;
            background-color: #D9D9D9;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 6px;
            .icon{
              height: 16px;
              width: 16px;
              color: #ffffff;
            }
            .checkedBox{
              position: absolute;
              right: -3px;
              top: -2px;
              height: 14px;
              width: 14px;
              border-radius: 50%;
              background-color: #00BC00;
              border: 1px solid #ffffff;
              display: flex;
              align-items: center;
              justify-content: center;
             
              &>img {
                width: 7px;
              }
            }
          }
        }
      }
    }

    .inputWrapper{
      width: 319px;
      margin-top: 16px;
      color: #262626;
      .inputWrapperTitle{
        font-size: 14px;
        margin-bottom: 12px;
        span:nth-child(2) {
          font-size: 12px;
        }
      }
      .inputBox{
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 52px;
        border-bottom: 0.5px solid #EAEAEA;
        .input{
          width: 180px;
          :global {
            .adm-input-element {
              font-size: 32px;
              font-weight: 600;
              line-height: 30px;
              &::placeholder {
                font-size: 20px;
                line-height: 30px;
                font-weight: normal;
              }
            }
          }
        }
        .inputBtnBox{
          width: 140px;
          display: flex;
          justify-content: end;
          align-items: center;
          span:nth-child(1) {
            font-size: 12px;
            color: #2976F1;
            cursor: pointer;
          }
          span:nth-child(2) {
            font-size: 12px;
            margin-left: 3px;
            color: #8C8C8C;
          }
          .clearIcon{
            margin-left: 3px;
            height: 14px;
            width: 14px;
            cursor: pointer;
          }
        }
      }
      .chooseBox{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 16px;
        .chooseLeft{
          font-size: 14px;
          font-weight: 600;
          color: #262626;
        }
        .chooseRight{
          display: flex;
          flex-direction: column;
          justify-content: end;
          align-items: end;
          span:nth-child(1) {
            font-size: 12px;
            color: #262626;
          }
          span:nth-child(2) {
            margin-top: 2px;
            font-size: 10px;
            color: #8C8C8C;
          }
        }
      }
    }
  }

  .checkBox {
    margin: 16px;
    padding: 12px 16px;
    background: #fff;
    border-radius: 8px;
    .title {
      width: 100%;
      height: 38px;
      background: linear-gradient(180deg, #FDF2E5 0%, #FBE4CE 100%);
      border-radius: 6px 6px 0px 0px;
      text-align: center;
      line-height: 38px;
      font-weight: 400;
      font-size: 12px;
      color: #DE9960;
    }
    h3 {
      font-weight: 600;
      font-size: 16px;
      color: #333;
      padding: 12px 16px 0;
      span {
        font-size: 12px;
        color: #BB6C1D;
        font-weight: normal;
      }
    }
    .loanTermList {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 0 16px;
      margin-top: 16px;
      .loanTermItem {
        width: 101px;
        height: 30px;
        background-color: #f3f4f6;
        text-align: center;
        border-radius: 5px;
        box-sizing: border-box;
        font-weight: 600;
        p {
          font-size: 14px;
          color: #8C8C8C;
          &.samllText {
            font-size: 12px;
          }
        }
        &.active {
          background-color: #FF2C3E;
          p {
            font-size: 14px;
            color: #fff;
          }
        }
        &.is360Channel {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 16px;
        }
      }
    }

    .bottomBody {
      // margin-top: 20px;
      box-sizing: border-box;
      // padding: 0 16px;
      p {
        width: 100%;
        display: flex;
        justify-content: center;
        span {
          display: inline-block;
          color: #c16818;
          height: 41px;
          line-height: 41px;
          padding: 0 16px;
          background-repeat: no-repeat;
          background-size: 100% 100%;
          background-image: url("~@imgs/register/new-theme/bottom-body-bg.png");
        }
      }
      .phoneBox {
        height: 46px;
        background: #f4f5f7;
        border-radius: 8px;
        display: flex;
        align-items: center;
        margin: 9px auto 0;
        padding-left: 16px;
        .label {
          width: 170px;
          text-align: center;
          font-size: 16px;
        }
        :global {
          .adm-input-element {
            font-size: 16px;
            line-height: 30px;
            &::placeholder {
              font-size: 16px;
              line-height: 30px;
            }
          }
        }
      }
      .getCms {
        margin-top: 12px;
        font-weight: 600;
        height: 46px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--color-primary);
        border-radius: 6px;
        color: #FFFFFF;
        font-size: 16px;
      }
      .agreement {
        @include flex-cc;
        flex-wrap: wrap;

        .primary {
          color: var(--color-primary);
        }
    
        margin-top: 20px;
        font-size: 12px;
        color: #8C8C8C;
        line-height: 18px;

        &.animation {
          position: relative;
          animation: shake .1s;
        }

        @keyframes shake {
          0% {
            left: 8px;
          }

          50% {
            left: -8px;
          }
    
          100% {
            left: 0;
          }
        }
    
        .checked {
          margin-right: 6px;
          border: 1px solid #D9D9D9;
          border-radius: 100%;
          width: 16px;
          height: 16px;
    
          &.active {
            position: relative;
            border: none;
            background: var(--color-primary);
    
            &::before {
              position: absolute;
              top: 4px;
              left: 3px;
              width: 10px;
              height: 7px;
              content: "";
              background-image: url("~@imgs/activity/check.png");
              background-size: 100%;
            }
          }
        }
      }
    }
  }
  .scrolledList{
    margin:16px 0 16px 16px;
    .scrollTips {
      display: flex;
      justify-content: start;
      align-items: center;
      margin-bottom: 12px;
      div{
        width: 4px;
        height: 14px;
        background-color: var(--color-primary);
      }
      span {
        margin-left: 6px;
        font-size: 14px;
        color: #000000;
        font-weight: 600;
      }
    }
    .scrolledBox{
      overflow-x: scroll;
      width: 359px;
      display: flex;
      .userCard{
        flex-shrink: 0;
        width: 220px;
        height: 104px;
        border-radius: 6px;
        margin-right: 12px;
        padding: 12px;
        background-color: #fff;
        .userHeader{
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 10px;
          .userHeaderLeft{
            display: flex;
            justify-content: start;
            img {
              height: 38px;
              width: 38px;
              border-radius: 50%;
            }
            .userInfo{
              margin-left: 6px;
              display: flex;
              flex-direction: column;
              align-items: start;
              justify-content: center;
              span:nth-child(1){
                font-size: 13px;
                font-weight: 700;
                color: #262626;
              }
              span:nth-child(2) {
                font-size: 11px;
                color: #262626;
              }
            }
          }
          .userHeaderRight{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: end;
            font-size: 10px;
            color: #8C8C8C;
            .userAmount{
              color: var(--color-primary);
              font-size: 14px;
              font-weight: 600;
            }
          }
        }
        .userTime{
          font-size: 11px;
          color: #8C8C8C;
        }
      }
    }
  }

  .tipBox {
    padding-bottom: 50px;
    .tip {
      font-size: 10px;
      line-height: 20px;
      text-align: center;
      color: $text-gray-color;
    }
  }

  .dialogOverlay {
    width: 100%;
    height: 100vh;
    background-color: rgba(0,0,0,.7);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    display: none;
    &.showPopup {
      display: block;
    }
    .dialogMain {
      width: 320px;
      height: 180px;
      border-radius: 8px;
      background-color: #fff;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      padding: 20px 0;
      box-sizing: border-box;
      .close {
        width: 100%;
        font-size: 20px;
        text-align: right;
        margin-right: 20px;
        color: rgb(214, 217, 223);
        padding-right: 20px;
        box-sizing: border-box;
      }
      .inputBox {
        width: 285px;
        height: 48px;
        border: 1px solid #e19459;
        margin: 10px auto;
        padding-left: 20px;
        border-radius: 8px;
        display: flex;
        align-items: center;
        .code {
          width: 150px;
          text-align: center;
          color: #e19459;
        }
        :global {
          .adm-input-element {
            font-size: 15px;
            line-height: 30px;
          }
        }
      }
      .getQuota {
        margin: 18px auto;
        width: 283px;
        height: 44px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-image: linear-gradient(180deg,#ffcfa0 1%,#e19459);
        border-radius: 6px;
        color: #FFFFFF;
        font-size: 16px;
      }
    }
  }
}

:global {
  .adm-auto-center {
    .adm-auto-center-content {
      text-align: center !important;
    }
  }
}
