@import "@/styles/var.scss";
@import "@/styles/mixin.scss";
@font-face {
  font-family: 'Anek';
  src: url('../fill-information/Anek Bangla Condensed Thin.ttf');
}

.fillInformation {
  position: relative;
  padding: 48px 0 100px;
  min-height: 100vh;
  background: linear-gradient( 180deg, #FFEAEC 0%, #F2F3F5 50%);
  * {
    font-family: 'Anek';
  }
  .headerBox {
    width: calc(100% - 32px);
    height: 155px;
    background-image: url('~@imgs/form-result/bg3.png');
    background-size: cover;
    margin: 16px 16px 0;
    padding: 14px 20px 30px;
    .headerIntroduce{
       font-size: 14px;
       color: #F2F3F5;
       line-height: 18px;
       padding-bottom: 12px;
    }
    .limitBox{
      margin-bottom: 12px;
    }
    .headerLimit {
      color: #F2F3F5;
      font-size: 35px;
      font-weight: 600;
    }
    .headerText {
      background: rgba($color: #ffffff, $alpha: .2);
      padding: 0 6px;
      border-radius: 3px 3px 3px 3px;
      height: 18px;
      font-size: 10px;
      color: #ffffff;
    }
    .progress{
      padding-top: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .progressBar {
        width: 85%;
        height: 12px;
        border-radius: 15px 15px 15px 15px;
        background: #FFFFFF;
        position: relative;
        overflow: hidden;
        .progressLine{
          width: 100%;
        height: 12px;
          border-radius: 15px 15px 15px 15px;
          background: repeating-linear-gradient(
            70deg,
            #ffbc3a,
            #ffbc3a 15px,
            #ffedcb 15px,
            #ffedcb 30px
          );
          transition: transform 0.3s ease; /* 添加过渡效果 */
          transform: translate(-100%);
        }
      }
      .progressPercentage {
        width: 15%;
        font-size: 14px;
        color: #F2F3F5;
        font-weight: 600;
        line-height: 18px;
        text-align: right;
      }
    }
    
    // .progressInfo {
    //   padding: 0 20px;
    //   margin-top: 16px; 
    //   display: flex;
    //   justify-content: space-between;
    //   .progressInfoLeft {
    //     .progressLimit {
    //       color: #FF041D;
    //       font-size: 24px;
    //       font-weight: 600;
    //     }
    //     .progressText {
    //       margin-top: 2px;
    //       color: #AAA;
    //       font-size: 12px;
    //     }
    //   }
    //   .progressInfoRight {
    //     display: flex;
    //     align-items: baseline;
    //     .currentStepNum {
    //       font-size: 24px;
    //       font-weight: 600;
    //       color: #000;
    //     }
    //     .totalStepNum {
    //       font-size: 12px;
    //       font-weight: 600;
    //       color: #000;
    //     }
    //   }
    // }
  }
  .lastBox {
    margin-top: 8px;
    padding: 0 16px;
    .lastBoxItem {
      margin-bottom: 18px;
    }
    .formHeader {
      display: flex;
      .formHeaderIcon {
        margin-top: 3px;
        width: 15px;
        height: 16px;
      }
      .formHeaderRight {
        margin-left: 5px;
        .formHeaderTitle {
          font-size: 13px;
          font-weight: 600;
          color: #000;
        }
        .formHeaderSubTitle {
          margin-top: 2px;
          font-size: 10px;
          color: #AAA;
        }
      }
    }
    .formBox {
      margin-top: 8px;
      padding: 0 12px;
      background-color: #F5F6F8;
      border-radius: 8px;
      .line {
        width: 100%;
        border-bottom: var(--border-inner)
      }
      .content {
        margin-top: 6px;
      }
    }
  }
  .city {
    :global {
      .adm-list-item-content-prefix {
        width: 140px;
      }
    }
  }


  .selectBox {
    @include flex-sbc;

    padding: 10px 0 10px;
    // margin-bottom: 10px;

    & + .selectBox {
      border-top: 1px solid #ddd;
    }

    .left {
      font-size: 14px;
    }

    .right {
      display: flex;
      font-size: 14px;


      >span {
        @include flex-cc;

        border-radius: 4px;
        min-width: 58px;
        padding: 0 7px;
        font-size: 14px;
        height: 30px;
        background: #E0E0E0;

        & + span {
          margin-left: 11px;
        }

        &.active {
          color: $color-white;
          background: linear-gradient(108.61deg, #FF606E 3.46%, #FF192C 97.1%) !important;
        }
      }
    }

    // &.payoffForm {
    //   .right > span { 
    //     background: #fff;
    //   }
    // }
  }

  .stepBoxFix {
    // position: fixed;
    // left: 20px;
    // top: 120px;
    margin-top: 5px;
    padding: 0 20px;
    font-size: 12px;
    color: #000;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .userInfoForm{
    margin: -10px 16px 0;
  }
}
.popupBox{
  height: 100%;
  background-image: url('~@imgs/form-result/bg4.png');
  background-size:cover;
  padding: 12px 16px 26px;
  .popupTitle{
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    img{
      height: 22px;
      width: 22px;
      padding-right: 10px;
    }
    h1 {
      font-size: 18px;
      color: #000000;
      line-height: 24px;
    }
  }
  .popupContent{
    height: calc(100% - 100px);
    overflow:scroll
  }
  .popupBtnList{
    margin-top: 22px;
    display: flex;
    justify-content: space-between;
    .btnCancle{
      border: none;
      padding: 0;
      width: 150px;
      height: 48px;
      font-size: 16px;
      font-weight: bold;
      background: #F6F6F6;
      line-height: 48px;
      color: #999999;
    }
    .btnOk{
      border: none;
      padding: 0;
      width: 150px;
      height: 48px;
      font-size: 16px;
      font-weight: bold;
      background: var(--linear-gradient);
      line-height: 48px;
      color: #FFFFFF;
    }
  }
}
  .AuthorizeCommonPopup{
    position: relative;
    height: 100%;
    background: linear-gradient( 180deg, #FFE3E1 0%, #FAFAFA 20%);
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    padding: 6px 16px 0;
    .successBg{
      height: 128px;
      width: 128px;
      position: absolute;
      right: 1px;
      top: 5px;
      z-index: 1;
    }
    .overlay{
      position: relative;
      z-index: 2;
      background-color: transparent;
      height: 100%; /* 确保 overlay 高度为 100% */
      display: flex;
      flex-direction: column; /* 确保子元素以列的形式排列 */
      h1 {
        font-size: 21px;
        color: #000000;
        margin-bottom: 10px;
        font-weight: 800;
      }
      .iconSuccess{
        height: 16px;
        width: 16px;
      }
      .seconedTitle {
        display: flex;
        margin-bottom: 20px;
        img {
          margin-right: 4px;
        }
        span{
          font-size: 13px;  
          color: #333333;
        }
      }
      .content{
        flex: 1;
        overflow: auto; 
        -ms-overflow-style: none;  /* 对于 IE 和 Edge */
        scrollbar-width: none;  /* 对于 Firefox */
      }
    }
  }
  ::-webkit-scrollbar {
    display: none; /* 对于 Chrome, Safari 和 Opera */
  }