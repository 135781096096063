@import "@/styles/var.scss";
@import "@/styles/mixin.scss";

.authorizePage {
  padding: 48px 0 130px;
  line-height: normal;
  background-color: #F5F6F8;
  min-height: 100vh;
  .headerBox {
    width: 100%;
    height: 32px;
    .textTips {
      width: 100%;
      height: 100%;
    }
  }

  .mainContent {
    padding: 0 16px;
    .limitBox {
      margin: 16px auto 8px;
      padding: 16px;
      border-radius: 8px;
      border: 1px solid #FFF;
      background: linear-gradient(180deg, #FFF 0%, #F3F5FE 100%);
      box-shadow: 0px 4px 30px 0px rgba(153, 153, 153, 0.10);
      .limitBoxTitle {
        color: #50515C;
        font-size: 12px;
      }
      .limitNum {
        margin-top: 8px;
        color: #262626;
        font-size: 37px;
        font-weight: bold;
      }
      .splitLine {
        margin: 12px 0;
        width: 100%;
        height: 1px;
        background-color: #ddd;
      }
      .limitBoxFooter {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .footerItem {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 12px;
          color: #8C8C8C;
          .footerItemIcon {
            width: 11px;
            height: 12px;
            margin-right: 4px;
          }
        }
      }
    }

    .loanDurationBox {
      margin: 0 auto 8px;
      padding: 16px;
      border-radius: 8px;
      background-color: #fff;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 15px;
      .loanDurationBoxRight {
        display: flex;
        align-items: center;
        font-weight: bold;
        .arrowBlack {
          width: 8px;
          height: 8px;
          margin-left: 8px;
        }
        
      }
    }

    .yqlWholeProcessBox {
      margin: 8px auto;
      padding: 16px;
      border-radius: 10px;
      background-image: url("~@imgs/information/card-bg.png");
      background-size: 100% 100%;
      box-shadow: 0px 4px 30px 0px rgba(153, 153, 153, 0.10);
      .yqlWholeProcessBoxHeader {
        display: flex;
        align-items: center;
        .yqlWholeProcessBoxHeaderIcon {
          width: 21px;
          height: 21px;
          margin-right: 9px;
        }
        .yqlWholeProcessBoxHeaderTitle {
          font-size: 17px;
          font-weight: bold;
          color: #000;
        }
      }
      .yqlWholeProcessBoxContent {
        .yqlWholeProcessBoxContentItem {
          padding: 12px 0;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .contentItemName {
            flex-shrink: 0;
            font-size: 16px;
            font-weight: bold;
            color: #333;
          }
          .contentItemRight {
            margin-left: 26px;
            display: flex;
            align-items: center;
            .contentItemText {
              font-size: 16px;
            }
            .contentItemIcon {
              width: 36px;
              height: 36px;
              margin-left: 10px;
            }
          }
        }
      }
      .splitLine {
        margin: 12px 0;
        width: 100%;
        height: 1px;
        background-color: #ddd;
      }
    }

    .productBox {
      padding: 16px 0;
      border-radius: 8px;
      background-color: #fff;
      .productTitle {
        display: flex;
        align-items: center;
        font-weight: 600;

        .titleLine {
          width: 4px;
          height: 16px;
          flex-shrink: 0;
          background: var(--color-primary);
          margin-right: 12px;
        }
      }
      .splitLine {
        width: 311px;
        margin: 20px auto 15px;
        height: 1px;
        background: #DDD;
      }
      .productNameBar {
        padding: 0 16px;
        display: flex;
        align-items: center;
        color: #50515C;
        font-size: 15px;
        font-weight: 600;
        .productIcon {
          width: 20px;
          height: 20px;
          margin-right: 8px;
        }
      }
      .companyBox {
        margin-top: 8px;
        padding: 0 16px;
        display: flex;
        align-items: center;
        color: #8C8C8C;
        font-size: 12px;
        .companyIcon {
          width: 16px;
          height: 16px;
          margin-right: 8px;
        }
      }
    }

    .protocolBox {
      margin-top: 8px;
      padding: 16px;
      border-radius: 8px;
      background-color: #fff;
      .specialTips {
        color: #8C8C8C;
        font-size: 12px;
      }
      .protocolBoxContent {
        margin-top: 12px;
        padding: 20px 16px;
        width: 311px;
        height: 255px;
        border-radius: 8px;
        background: #F2F5FD;
        box-sizing: border-box;
        overflow-y: auto;
        .protocolBoxTitle {
          color: #262626;
          text-align: center;
          font-size: 15px;
          font-weight: bold;
          margin-bottom: 12px;
        }
        p {
          color: #50515C;
          font-size: 14px;
          line-height: 24px;
        }
      }
    }
  }

  .footerBox {
    padding: 15px 6px;
    width: 100%;
    position: fixed;
    left: 0;
    bottom: 0;
    background-color: #fff;
    box-sizing: border-box;
    .btnBox {
      padding: 0 16px;
      // margin-top: 16px;
      .btn {
        border: none;
        padding: 0;
        height: 48px;
        background: $linear-gradient;
        line-height: 50px;
        font-size: 18px;
        font-weight: bold;
      }
    }
    .footerText {
      margin-bottom: 10px;
      font-size: 10px;
      color: #8C8C8C;
      text-align: center;
      line-height: 16px;
      display: flex;
      justify-content: center;

      .checkedIcon {
        flex-shrink: 0;
        width: 14px;
        height: 14px;
        margin-right: 8px;
        margin-bottom: .5px;
      }
  
      .checked {
        flex-shrink: 0;
        // display: inline-block;
        vertical-align: text-bottom;
        margin-right: 8px;
        margin-bottom: .5px;
        border: 1px solid #D9D9D9;
        border-radius: 100%;
        width: 14px;
        height: 14px;
    
        &.active {
          position: relative;
          border: none;
          background: var(--color-primary);
    
          &::before {
            position: absolute;
            top: 4px;
            left: 3px;
            width: 9px;
            height: 6px;
            content: "";
            background-image: url("~@imgs/activity/check.png");
            background-size: 100%;
          }
        }
      }
  
      .footerTextRed {
        // font-size: 12px;
        color: var(--color-primary);
      }
    }
    .paddingBox {
      height: constant(safe-area-inset-bottom);
      height: env(safe-area-inset-bottom);
      background-color: #fff;
    }
  }

}

.monthBox {
  background-color: $background-color;

  .row {
    padding: 16px;
    font-size: 16px;
    text-align: center;

    &.active {
      font-weight: bold;
      color: $color-primary;
      background-color: $color-white;
    }
  }
}

.protcolPopup {
  .popupTitle {
    padding: 15px;
    font-size: 18px;
    font-weight: bold;
  }
  .protocolIdsBox {
    padding: 0 15px;
    .protocolIdItem {
      padding: 15px 0;
      border-bottom: 1px solid #DDD;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 14px;
      .arrowBlack {
        width: 10px;
      }
      .chooseArrow {
        transform: rotate(90deg);
      }
    }
    .protocolChild{
      padding-left: 20px;
      height: 40px;
      font-size: 14px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}

#loading-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  flex-flow: column;
  align-items: center;
}

@-webkit-keyframes loadingRotate {
  0% {
    transform: rotate(0deg) scale(1);
  }

  50% {
    transform: rotate(180deg) scale(0.6);
  }

  100% {
    transform: rotate(360deg) scale(1);
  }
}

@keyframes loadingRotate {
  0% {
    transform: rotate(0deg) scale(1);
  }

  50% {
    transform: rotate(180deg) scale(0.6);
  }

  100% {
    transform: rotate(360deg) scale(1);
  }
}
#root{
  height: 100%;
}
.ballClipRotateMultiple {
  position: relative;
  width: 50px;
  height: 50px;
}

.ballClipRotateMultiple>div {
  position: absolute;
  left: 0px;
  top: 0px;
  border: 2.5px solid #007AEA;
  border-bottom-color: #0000;
  border-top-color: #0000;
  border-radius: 100%;
  height: 35px;
  width: 35px;
  -webkit-animation: loadingRotate 1s 0s ease-in-out infinite;
  animation: loadingRotate 1s 0s ease-in-out infinite;
}

.ballClipRotateMultiple>div:last-child {
  display: inline-block;
  top: 10px;
  left: 10px;
  width: 15px;
  height: 15px;
  -webkit-animation-duration: .5s;
  animation-duration: .5s;
  border-color: #007AEA #0000;
  -webkit-animation-direction: reverse;
  animation-direction: reverse;
}

#loading-box .dot {
  display: inline-block;
  border-radius: 50%;
  width: 2px;
  height: 2px;
  animation: dotting .8s infinite step-start;
}

@keyframes dotting {
  25% {
    box-shadow: 2px 0 0 #007AEA;
  }

  50% {
    box-shadow: 2px 0 0 #007AEA, 6px 0 0 #007AEA;
  }

  75% {
    box-shadow: 2px 0 0 #007AEA, 6px 0 #007AEA, 10px 0 0 #007AEA;
  }
}

@-webkit-keyframes dotting {
  25% {
    box-shadow: 2px 0 0 #007AEA;
  }

  50% {
    box-shadow: 2px 0 0 #007AEA, 6px 0 0 #007AEA;
  }

  75% {
    box-shadow: 2px 0 0 #007AEA, 6px 0 #007AEA, 10px 0 0 #007AEA;
  }
}
