@import "@/styles/var.scss";
@import "@/styles/mixin.scss";

.jkrFormPage {
  padding: 48px 20px 40px;
  min-height: 100vh;
  background-color: #fff;
  .limitBox {
    margin: 16px auto 16px;
    padding: 12px 16px;
    border-radius: 8px;
    border: 1px solid #FFF;
    background: linear-gradient(180deg, #FFF 0%, #F3F5FE 100%);
    box-shadow: 0px 4px 30px 0px rgba(153, 153, 153, 0.10);
    .limitBoxTitle {
      color: #50515C;
      font-size: 12px;
    }
    .limitNum {
      margin: 12px 0;
      color: #262626;
      font-size: 37px;
      line-height: 52px;
      font-weight: bold;
    }
    .splitLine {
      margin: 12px 0;
      width: 100%;
      height: 1px;
      background-color: #ddd;
    }
    .limitBoxFooter {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .footerItem {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        color: #8C8C8C;
        .footerItemIcon {
          width: 11px;
          height: 12px;
          margin-right: 4px;
        }
      }
    }
  }
  .lastBoxItem {
    margin-bottom: 18px;
    .formHeader {
      display: flex;
      .formHeaderIcon {
        margin-top: 3px;
        height: 16px;
      }
      .formHeaderRight {
        margin-left: 5px;
        .formHeaderTitle {
          font-size: 13px;
          font-weight: 600;
          color: #000;
        }
        .formHeaderSubTitle {
          margin-top: 2px;
          font-size: 10px;
          color: #AAA;
        }
      }
    }
    .formBox {
      margin-top: 8px;
      margin-bottom: 16px;
      padding: 0 12px;
      background-color: #F5F6F8;
      border-radius: 8px;
      .line {
        width: 100%;
        border-bottom: var(--border-inner)
      }
      .content {
        margin-top: 6px;
      }
    }
  }
  .btn {
    margin: 0 auto;
    border: none;
    padding: 0;
    width: 335px;
    height: 48px;
    font-size: 16px;
    font-weight: bold;
    background: $linear-gradient;
    line-height: 48px;
  }

  .formItemContent{
    margin-top: 16px;
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 8px;
    .formItem{
      height: 28px;
      background: #F2F3F5;
      border-radius: 2px 2px 2px 2px;
      font-size: 14px;
      line-height: 28px;
      text-align: center;
      color: #262626;
    }
    .activeItem {
      border: 1px solid var(--color-primary);
      background: color-primary-fn(0.1);
      color: var(--color-primary) !important;
      font-weight: 600;
    }
  }

  :global {
    .adm-input-element {
      font-size: 14px;
      text-align: right;
      color: #262626;
      background-color: #F5F6F8;
    }

    .adm-list-body {
      background-color: transparent;
    }

    .adm-list-card {
      margin: 0;
    }

    .adm-list-item {
      padding: 0;
    }

    .adm-list-item-content {
      padding-right: 0;
      background-color: #F5F6F8;
      border: none;
    }

    .adm-form-item-label .adm-form-item-required-asterisk {
      display: none;
    }

    input:-webkit-autofill {
      box-shadow: 0 0 0 1000px #F5F6F8 inset;
    }

    .adm-form-item-label {
      height: 20px;
      font-size: 14px;
      color: #000;
      background-color: transparent;
    }
    .adm-list-item-content-prefix {
      @include flex-vc;
    }

    .adm-list-item-content-arrow {
      transform: rotate(90deg);
      font-size: 14px;
    }

    .adm-form-item-feedback-error {
      text-align: right;
    }

    // .adm-swiper-track-inner {
    //   transition: none !important;
    // }
  }
}
