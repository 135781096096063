@import "@/styles/var.scss";
@import "@/styles/mixin.scss";

.jointLoginPopup {
  :global {
    .adm-popup-body {
      background-color: transparent;
    }
  }
  .jointLoginBox {
    width: 100%;
    padding-top: 30px;
    padding-bottom: calc(21px + constant(safe-area-inset-bottom));
    padding-bottom: calc(21px + env(safe-area-inset-bottom));
    border-radius: 16px 16px 0 0;
    overflow: hidden;
    background-color: #fff;
    background-image: url("~@imgs/form-result/bg2.png");
    background-size: 100% 100%;
    line-height: normal;
    .productBox {
      display: flex;
      align-items: center;
      justify-content: center;
      .productLogo {
        width: 25px;
        height: 25px;
        margin-right: 11px;
        border-radius: 4px;
      }
      .productName {
        font-size: 16px;
        font-weight: bold;
      }
    }
    .limitBox {
      margin-top: 30px;
      text-align: center;
      font-size: 39px;
      font-weight: bold;
      color: #0D0000;
      .limitBoxText {
        font-size: 14px;
        color: #A8A8A8;
        margin-top: 2px; 
      }
    }
    .btnBox {
      padding: 0 26px;
      margin-top: 28px;
      .btn {
        border: none;
        padding: 0;
        height: 58px;
        background: $linear-gradient;
        .btnText {
          font-size: 17px;
          font-weight: bold;
        }
        .btnTips {
          margin-top: 2px;
          font-size: 12px;
        }
      }
    }
  
    .checkedIcon {
      flex-shrink: 0;
      width: 14px;
      height: 14px;
      margin-right: 8px;
      margin-bottom: .5px;
    }
  
    .agreementBox {
      margin-top: 15px;
      padding: 0 30px;
      font-size: 12px;
      color: #8C8C8C;
      line-height: 16px;
      display: flex;
      justify-content: center;
  
      .checkedIcon {
        flex-shrink: 0;
        width: 14px;
        height: 14px;
        margin-right: 8px;
        margin-bottom: .5px;
      }
  
      .checked {
        flex-shrink: 0;
        vertical-align: text-bottom;
        margin-right: 8px;
        margin-bottom: .5px;
        border: 1px solid #D9D9D9;
        border-radius: 100%;
        width: 14px;
        height: 14px;
    
        &.active {
          position: relative;
          border: none;
          background: #FF3042;
    
          &::before {
            position: absolute;
            top: 4px;
            left: 3px;
            width: 9px;
            height: 6px;
            content: "";
            background-image: url("~@imgs/activity/check.png");
            background-size: 100%;
          }
        }
      }
  
      .agreementItem {
        color: #FF3042;
      }
    }
  }
}
