@import "@/styles/var.scss";
@import "@/styles/mixin.scss";

.institutionList {
  .productPopupBox {
    padding: 20px;
    border-radius: 8px;
    background-color: #fff;
    margin-bottom: 12px;
    .productInfo{
      display: flex;
      align-items: center;
      border-bottom: 1px solid #EEEEEE;
      padding-bottom: 14px;
      .contentItemIcon {
        width: 45px;
        height: 45px;
        margin-right: 15px;
      }
      .productName{
        font-size: 20px;
        color: #000000;
        font-weight: bold;
      }
    }
    .productCompany{
      display: flex;
      align-items: center;
      padding-top: 20px;
      .companyTitle{
        font-size: 16px;
        color: #000000;
        font-weight: 900;
        padding-right: 20px;
      }
      .companyName {
        font-size: 14px;
        color: #000000;
      }
    }
  }
    .normalProcessBox {
      display: flex;
      justify-content: start;
      align-items: center;
      .yqlWholeProcessBoxHeaderLeft{
        display: flex;
        align-items: center;
      }
      .yqlWholeProcessBoxHeaderRight{
        // height: 42px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
      }
      .yqlWholeProcessBoxHeaderIcon {
        width: 21px;
        height: 21px;
        margin-right: 9px;
      }
      .yqlWholeProcessBoxHeaderTitle {
        font-size: 17px;
        font-weight: bold;
        color: #000;
      }
    }
  .yqlWholeProcessBoxHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .yqlWholeProcessBoxHeaderLeft{
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: space-around;
      .seconedTitle {
        margin-top: 10px;
        display: flex;
        align-items: center;
        img {
          margin-right: 4px;
          width: 16px;
        }
        span{
          font-size: 10px;  
          color: #333333;
        }
      }
      .welcome{
        width: 100%;
        display: flex;
        justify-content: start;
        align-items: center;
      }
    }
    .yqlWholeProcessBoxHeaderRight{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      .unionIcon{
        height: 18px;
        margin-right: 2px;
      }
      .percentageIcon{
        width: 50px;
      }
    }
    .yqlWholeProcessBoxHeaderIcon {
      width: 21px;
      height: 21px;
      margin-right: 9px;
    }
    .yqlWholeProcessBoxHeaderTitle {
      font-size: 17px;
      font-weight: bold;
      color: #000;
    }
  }
  .splitLine {
    margin: 16px 0;
    width: 100%;
    height: 1px;
    background-color: #ddd;
  }
  .institutionListBox{
    margin: 8px auto;
    padding: 16px;
    border-radius: 10px;
    // background-image: url("~@imgs/information/card-bg.png");
    // object-fit: cover;
    // background-position: center;
    background-size:cover;
    box-shadow: 0px 4px 30px 0px rgba(153, 153, 153, 0.10);
    .multipleProcessBoxContent{
      .multipleProcessTitle{
        flex-shrink: 0;
        font-size: 16px;
        font-weight: bold;
        color: #333;
      }
      .multipleProcessContentItem{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 16px;
        .multipleProcessContentItemLeft{
          width: 90%;
          display: flex;
          justify-content: start;
          align-items: center;
          .contentItemIcon {
            width: 36px;
            height: 36px;
            margin-right: 10px;
            border-radius: 6px;
          }
          .multipleProcessContentItemInfo{
            span{
              font-size: 16px;
              color: #262626;
            }
            div {
              color: #8E8E8E;
              font-size: 12px;
            }
          }
          .multipleProductInfo{
            display: flex;
            flex-direction: column;
            justify-content: start;
            // align-items: center;
            .partnerCompanyName{
              color: #8E8E8E;
              font-size: 12px;
            }
            .multipleProductInfoTop{
              display: flex;
              justify-content: start;
              align-items: center;
              margin-bottom: 2px;
              .productName{
                font-weight: 700;
                font-size: 14px;
                color: #262626;
              }
              .platformName{
                text-align: center;
                margin-left: 10px;
                padding: 2px 4px;
                font-size: 12px;
                line-height: 1.3;
                color: #E4A26A;
                border: 1px solid #E4A26A;
                border-radius: 2px;
              }
            }
          }
        }
        .multipleProcessContentItemRight{
            :global {
              .adm-checkbox{
                --icon-size:14px
              }
              .adm-checkbox.adm-checkbox-checked .adm-checkbox-icon {
                border-color:color-primary-fn(0.1);
                background-color:color-primary-fn(0.1);
              }
              .adm-checkbox.adm-checkbox-disabled .adm-checkbox-icon.adm-checkbox-icon{
                background-color:#D9D9D9;
                border-color:#D9D9D9;
                color:#ffffff;
              }
            }
        }
      }
    }
  }
  .yqlWholeProcessBox {
    margin: 8px auto;
    padding: 16px;
    border-radius: 10px;
    background-image: url("~@imgs/information/card-bg.png");
    background-size: 100% 100%;
    box-shadow: 0px 4px 30px 0px rgba(153, 153, 153, 0.10);
    .yqlWholeProcessBoxContent {
      .yqlWholeProcessBoxContentItem {
        padding: 12px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .contentItemName {
          flex-shrink: 0;
          font-size: 16px;
          font-weight: bold;
          color: #333;
        }
        .contentItemRight {
          margin-left: 26px;
          display: flex;
          align-items: center;
          .contentItemText {
            font-size: 16px;
          }
          .contentItemIcon {
            width: 36px;
            height: 36px;
            margin-left: 10px;
          }
        }
      }
    }
  }
  .loanDurationBox {
    margin: 0 auto 8px;
    padding: 16px;
    border-radius: 8px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 15px;
    .loanDurationBoxRight {
      display: flex;
      align-items: center;
      font-weight: bold;
      .arrowBlack {
        width: 8px;
        height: 8px;
        margin-left: 8px;
      }
    }
  }
  .productBox {
    padding: 16px 0;
    border-radius: 8px;
    background-color: #fff;
    .productTitle {
      display: flex;
      align-items: center;
      font-weight: 600;

      .titleLine {
        width: 4px;
        height: 16px;
        flex-shrink: 0;
        background: #FF3042;
        margin-right: 12px;
      }
    }
    .splitLine {
      width: 311px;
      margin: 20px auto 15px;
      height: 1px;
      background: #DDD;
    }
    .productNameBar {
      padding: 0 16px;
      display: flex;
      align-items: center;
      color: #50515C;
      font-size: 15px;
      font-weight: 600;
      .productIcon {
        width: 20px;
        height: 20px;
        margin-right: 8px;
      }
    }
    .companyBox {
      margin-top: 8px;
      padding: 0 16px;
      display: flex;
      align-items: center;
      color: #8C8C8C;
      font-size: 12px;
      .companyIcon {
        width: 16px;
        height: 16px;
        margin-right: 8px;
      }
    }
  }
}
.monthBox {
  background-color: $background-color;

  .row {
    padding: 16px;
    font-size: 16px;
    text-align: center;

    &.active {
      font-weight: bold;
      color: $color-primary;
      background-color: $color-white;
    }
  }
}
