@import "@/styles/var.scss";
@import "@/styles/mixin.scss";

.skin5 {
  height: 100%;
  background-color: #f5f6f9;
  line-height: normal;

  .header {
    padding: 20px 16px 0px 16px;
    width: 100%;
    background-repeat: no-repeat;
    background-size: 100% 220px;
    background-image: url("~@imgs/register/new-theme/head-bg.png");

    .logoBox {
      font-size: 20px;
      font-weight: 600;
      line-height: 20px;
      display: flex;
      align-items: center;

      .logo {
        margin-right: 10px;
        width: 42px;
        height: 42px;
        background-size: 100%;
        background-image: url("~@imgs/register/red-theme/logo-big.png");
      }

      .logoBoxRight {
        display: flex;
        flex-direction: column;
        color: #fff;
        .youqianLogoText {
          width: 89px;
          // height: 18px
        }
        span {
          margin-top: 4px;
          font-size: 8px;
          font-weight: 400;
          line-height: 10.13px;
          letter-spacing: 2px;

        }
      }
    }

    .inputWrapper {
      height: 258px;
      margin-top: 24px;
      padding: 16px;
      width: 343px;
      background: linear-gradient(180deg, #FBE5D7 0%, #FFFFFF 27.54%);
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .inputWrapperNumberBox{
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 20px;
        height: 120px;
        // background-color: #fff;
        .inputWrapperTitle{
          color: var(--color-primary);
          font-weight: 700;
          font-size: 14px;
        }
        .inputWrapperNumber{
          font-weight: 700;
          font-size: 50px;
          line-height: 70px;
          color: var(--color-primary);
          z-index: 1;
          margin-bottom: 4px;
        }
        .inputWrapperTag{
          background-color:#DE9960;
          color: #fff;
          font-size: 12px;
          width: 100%;
          border-radius: 4px;
          line-height: 16px;
          text-align: center;
          padding: 3px 8px;
        }
        .lineBox{
          position: absolute;
          top:50%;
          transform: translate(0,-50%);
          .line{
            height: 1px;
            width: 230px;
            background-color: #F2F2F2;
            margin-bottom: 12px;
          }
        }
      }
      .intruduceIcons{
        width: 100%;
        margin: 0 16px;
        padding: 0 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .iconBox{
          height: 86px;
          .icon{
            height: 58px;
            width: 58px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #FFEAEC;
            margin-bottom: 8px;
            img{
              height: 30px;
            }
          }
          .introduce{
            text-align: center;
            font-weight: 700;
            font-size: 14px;
            line-height: 20px;
          }
        }
      }
    }
  }

  .checkBox {
    // width: 352px;
    margin: 16px;
    // padding-bottom: 32px;
    background: #fff;
    border-radius: 8px;
    .title {
      width: 100%;
      height: 38px;
      background: linear-gradient(180deg, #FDF2E5 0%, #FBE4CE 100%);
      border-radius: 6px 6px 0px 0px;
      text-align: center;
      line-height: 38px;
      font-weight: 400;
      font-size: 12px;
      color: #DE9960;
    }
    h3 {
      font-weight: 600;
      font-size: 16px;
      color: #333;
      padding: 12px 16px 0;
      span {
        font-size: 12px;
        color: #BB6C1D;
        font-weight: normal;
      }
    }

    .bottomBody {
      box-sizing: border-box;
      padding: 16px;
      p {
        width: 100%;
        display: flex;
        justify-content: center;
        span {
          display: inline-block;
          color: #c16818;
          height: 41px;
          line-height: 41px;
          padding: 0 16px;
          background-repeat: no-repeat;
          background-size: 100% 100%;
          background-image: url("~@imgs/register/new-theme/bottom-body-bg.png");
        }
      }
      .phoneBox {
        height: 46px;
        background: #f4f5f7;
        border-radius: 8px;
        display: flex;
        align-items: center;
        margin: 9px auto 0;
        padding-left: 16px;
        .label {
          width: 170px;
          text-align: center;
          font-size: 16px;
        }
        :global {
          .adm-input-element {
            font-size: 16px;
            line-height: 30px;
            &::placeholder {
              font-size: 16px;
              line-height: 30px;
            }
          }
        }
      }
      .getCms {
        margin-top: 12px;
        height: 53px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--color-primary);
        border-radius: 6px;
        color: #FFFFFF;
        .btnFont{
          position: relative;
          height: 29px;
          font-size: 21px;
          font-weight: 700;
          .btnTag{
            position: absolute;
            right: -78px;
            top: -23px;
            width: 64px;
            height: 30px;
            padding: 4px 8px 4px 8px;
            border-radius: 30px 50px 50px 0px;
            font-size: 15px;
            line-height: 21px;
            background: linear-gradient(180deg, #FFC995 0%, #DE9960 100%);
          }
        }
      }
      .agreement {
        @include flex-cc;
        flex-wrap: wrap;

        .primary {
          color: #DE9960;
        }
    
        margin-top: 20px;
        font-size: 12px;
        color: #8C8C8C;
        line-height: 18px;

        &.animation {
          position: relative;
          animation: shake .1s;
        }

        @keyframes shake {
          0% {
            left: 8px;
          }

          50% {
            left: -8px;
          }
    
          100% {
            left: 0;
          }
        }
    
        .checked {
          margin-right: 6px;
          border: 1px solid #D9D9D9;
          border-radius: 100%;
          width: 14px;
          height: 14px;
    
          &.active {
            position: relative;
            border: none;
            background: #DE9960;
    
            &::before {
              position: absolute;
              top: 4px;
              left: 2px;
              width: 10px;
              height: 7px;
              content: "";
              background-image: url("~@imgs/activity/check.png");
              background-size: 100%;
            }
          }
        }
      }
    }
  }

  .advantageBox{
    margin: 0 16px 16px;
    padding: 16px;
    border-radius: 8px;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: right top;
    background-size: 108px;
    background-image: url('~@imgs/register/baidu-theme/background.png');
    .advantageBoxTitle{
      display: flex;
      padding: 0 10px;
      justify-content: space-between;
      align-items: center;
      .name {
        font-size: 18px;
        font-weight: 700;
      }
      .lineLeft{
        background: linear-gradient(90deg, #FEF8F8 0%, #FF2C3E 100%);
        height: 3px;
        width: 80px;
      }
      .lineRight{
        background: linear-gradient(90deg, #FF2C3E 0%, #FEF8F8 100%);
        height: 3px;
        width: 80px;
      }
    }
    .advantageTips{
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 6px;
      font-size: 14px;
      color: #262626;
    }
    .advantageIcons{
      display: grid;
      grid-template-columns: repeat(2, 1fr); /* 每行显示2个项目 */
      gap: 10px; /* 项目之间的间隔 */
      margin-top: 20px;
      .advantageItem{
        height: 70px;
        width: 154px;
        border-radius: 4px;
        padding: 16px 12px;
        background: linear-gradient(259.02deg, #FFF1F2 0.77%, rgba(255, 242, 243, 0.4) 100%);
        .advantageTop{
          height: 28px;
          display: flex;
          align-items: center;
          .advantageIcon{
            height: 28px;
            width: 28px;
          }
          span {
            margin-left: 5px;
            margin-bottom: 5px;
            font-size: 16px;
            font-weight: 600;
            color: #262626;
          }
        }
        .advantageBottom{
          font-size: 10px;
          color: #8C8C8C;
          margin-top: 4px;
        }
      }
    }
  }

  .tipBox {
    padding-bottom: 50px;
    .tip {
      font-size: 10px;
      line-height: 20px;
      text-align: center;
      color: $text-gray-color;
    }
  }

  .dialogOverlay {
    width: 100%;
    height: 100vh;
    background-color: rgba(0,0,0,.7);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    display: none;
    &.showPopup {
      display: block;
    }
    .dialogMain {
      width: 320px;
      height: 180px;
      border-radius: 8px;
      background-color: #fff;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      padding: 20px 0;
      box-sizing: border-box;
      .close {
        width: 100%;
        font-size: 20px;
        text-align: right;
        margin-right: 20px;
        color: rgb(214, 217, 223);
        padding-right: 20px;
        box-sizing: border-box;
      }
      .inputBox {
        width: 285px;
        height: 48px;
        border: 1px solid #e19459;
        margin: 10px auto;
        padding-left: 20px;
        border-radius: 8px;
        display: flex;
        align-items: center;
        .code {
          width: 150px;
          text-align: center;
          color: #e19459;
        }
        :global {
          .adm-input-element {
            font-size: 15px;
            line-height: 30px;
          }
        }
      }
      .getQuota {
        margin: 18px auto;
        width: 283px;
        height: 44px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-image: linear-gradient(180deg,#ffcfa0 1%,#e19459);
        border-radius: 6px;
        color: #FFFFFF;
        font-size: 16px;
      }
    }
  }
}

:global {
  .adm-auto-center {
    .adm-auto-center-content {
      text-align: center !important;
    }
  }
}
