@import "@/styles/var.scss";
@import "@/styles/mixin.scss";

.iosSkin {
  height: 100%;
  line-height: normal;
  background-color: #f5f6f9;
  position: relative;
  .downloadBar {
    width: 100%;
    padding: 6px 24px;
    background-color: rgba(0, 0, 0, .6);
    position: fixed;
    left: 0;
    top: 0;
    z-index: 999;
    display: flex;
    justify-content: space-between;
    .downloadAppLeft {
      display: flex;
      align-items: center;
      font-size: 14px;
      color: #fff;
      .downloadBarLogo {
        width: 24px;
        height: 24px;
        margin-right: 10px;
      }
    }
    .downloadBtn {
      padding: 0 10px;
      height: 24px;
      line-height: 24px;
      border-radius: 12px;
      background-color: #fff;
      font-size: 12px;
    }
  }
  .main {
    padding: 20px 16px 10px;
    background-repeat: no-repeat;
    background-size: 100% 432px;
    background-image: url("~@imgs/ios-skin/head-bg.png");

    .logoBox {
      font-size: 20px;
      font-weight: 600;
      line-height: 20px;

      @include flex-cc;

      .logo {
        margin-right: 10px;
        width: 49px;
        height: 49px;
        background-size: 100%;
        background-image: url("~@imgs/register/red-theme/logo-big.png");
      }

      .logoBoxRight {
        display: flex;
        flex-direction: column;
        .logoText {
          width: 80px;
          height: 21px
        }
        .youqianLogoText {
          width: 80px;
          height: 18px
        }
        .youqianTextLogoSvg{
          width: 80px;
          height: 20px;
          color: #ffffff;
        }
        .logoText2 {
          margin-top: 4px;
          width: 79px;
          height: 11px
        }
        .logoText3 {
          margin-top: 1px;
          font-size: 12px;
          color: #fff;
          font-weight: 600;
          transform: scale(.86) translateX(-10px);
        }
      }
    }

    .applyResultBox {
      margin-top: 15px;
      background: linear-gradient(314deg, #FFFAF9 0%, #FFEEE6 100%);
      border-radius: 7px;
      border: 2px solid var(--color-primary);
      display: flex;
      align-items: center;
      padding: 9px;
      .applyResultBoxIcon {
        width: 60px;
        height: 60px;
        flex-shrink: 0;
        margin-right: 8px;
      }
      .applyResultBoxRight {
        .applyResultBoxText1 {
          font-size: 16px;
          font-weight: bold;
          color: #000;
          .applyPrductionName {
            color: var(--color-primary);
          }
        }
        .applyResultBoxText2 {
          margin-top: 4px;
          font-size: 14px;
          color: #967569;
          .highlight {
            color: var(--color-primary);
          }
        }
      }
    }

    .containers {
      margin-top: 13px;
      border-radius: 14px;
      background-color: #fff;
      padding: 18px 12px;
      .noApplyResultBox {
        margin-bottom: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        .noApplyResultBoxIcon {
          width: 17px;
          height: 17px;
          margin-right: 10px;
        }
        .noApplyResultBoxText {
          font-weight: bold;
          font-size: 16px;
          color: #000;
        }
      }
      .swiperBox {
        background-color: #fef2ee;
        border-radius: 16px;
        padding: 8px;
        min-height: 270px;
        .swiperBoxItem {
          padding: 15px 9px;
          background-color: #fff;
          border-radius: 8px;
          .swiperItemProduction {
            padding: 4px 16px;
            border-radius: 4px;
            background-color: #F6F6F6;
            display: flex;
            align-items: center;
            .swiperItemProductionLogo {
              width: 14px;
              height: 14px;
              background-color: skyblue;
              margin-right: 7px;
              border-radius: 2px;
            }
            .swiperItemProductionName {
              font-size: 13px;
              font-weight: bold;
              color: #802413;
            }
            .swiperItemProductionLine {
              width: 1px;
              height: 10px;
              background-color: #D29C91;
              margin: 2px 10px 0;
            }
            .swiperItemProductionTips {
              font-size: 13px;
              color: #A54331;
            }
          }
          .swiperItemText1 {
            margin-top: 14px;
            font-size: 14px;
            font-weight: bold;
            color: #000;
            text-align: center;
          }
          .swiperItemText2 {
            margin-top: 8px;
            font-size: 39px;
            color: #000;
            font-weight: bold;
            text-align: center;
          }
          .LoanBox {
            margin: 5px auto;
            padding-top: 5px;
            width: 100px;
            height: 25px;
            position: relative;
            text-align: center;
            .LoanBoxBg {
              width: 100%;
              height: 100%;
              position: absolute;
              left: 0;
              top: 0;
            }
            .LoanBoxText {
              position: relative;
              font-size: 12px;
              color: #fff;
            }
          }
          .btn {
            margin: 20px auto 0;
            border: none;
            padding: 0;
            width: 257px;
            height: 48px;
            font-size: 16px;
            font-weight: bold;
            background: $linear-gradient;
            line-height: 48px;
          }
        }
      }
      .indicatorBox {
        margin: 30px 0 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        .indicatorBoxItem {
          width: 9px;
          height: 9px;
          border-radius: 50%;
          background-color: #E4D6D6;
          margin-right: 12px;
          &.active {
            background-color: var(--color-primary);
          }
        }
      }
      .productBox {
        padding: 14px 0 0;
        .title {
          font-size: 18px;
          font-weight: 600;
          margin-bottom: 10px;
        }
      }
      .card {
        margin-bottom: 8px;
        border-radius: 8px;
        padding: 13px 0;
        background-color: $color-white;
        border-bottom: 1px solid #EEEEEE;
        display: flex;
      
        &:last-child {
          margin-bottom: 0;
          border: none
        }
    
        .left {
          flex: 1;
          .productLimit {
            font-size: 19px;
            font-weight: bold;
            color: var(--color-primary)
          }
          .productCompanyBox {
            margin-top: 6px;
            display: flex;
            align-items: center;
            .productCompanyIcon {
              overflow: hidden;
              border-radius: 2px;
              width: 17px;
              height: 17px;
            }
            .productCompanyName {
              margin-left: 7px;
              font-size: 14px;
              color: #555555
            }
          }
        }
        .apply {
          @include flex-cc;
          flex-shrink: 0;
    
          border-radius: 40px;
          width: 88px;
          height: 36px;
          font-size: 14px;
    
          // font-weight: 600;
          color: $color-white;
          background: $linear-gradient;
          mix-blend-mode: normal;
          line-height: 20px;
    
          &.disable {
            background: $border-color;
          }
        }
      }
    }
    .footerBox {
      margin-top: 20px;
      background-color: #fff;
      height: 151px;
      border-radius: 14px;
      .titleBox {
        padding: 24px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        .titleLine {
          width: 54px;
          height: 7px;
        }
        .titleText {
          margin: 0 15px;
          font-size: 16px;
          font-weight: bold;
        }
      }
      .sortBox {
        display: flex;
        justify-content: center;
        .sortBoxItem {
          text-align: center;
          .sortBoxImg {
            width: 32px;
            height: 27px;
          }
          .sortBoxItemText {
            margin-top: 8px;
            font-size: 14px;
            color: #9C9C9C;
          }
        }
        .sortBoxArrow {
          width: 9px;
          height: 11px;
          margin: 10px 20px 0;
        }
      }
    }
    .footerText {
      padding: 0 24px 40px;
      margin-top: 12px;
      font-size: 12px;
      color: #CCCCCC;
      text-align: center;
    }
  }
}
.jointLoginPopup {
  :global {
    .adm-popup-body {
      background-color: transparent;
    }
  }
  .jointLoginBox {
    width: 100%;
    padding-top: 30px;
    padding-bottom: calc(21px + constant(safe-area-inset-bottom));
    padding-bottom: calc(21px + env(safe-area-inset-bottom));
    border-radius: 16px 16px 0 0;
    overflow: hidden;
    background-color: #fff;
    background-image: url("~@imgs/form-result/bg2.png");
    background-size: 100% 100%;
    line-height: normal;
    .productBox {
      display: flex;
      align-items: center;
      justify-content: center;
      .productLogo {
        width: 25px;
        height: 25px;
        margin-right: 11px;
        border-radius: 4px;
      }
      .productName {
        font-size: 16px;
        font-weight: bold;
      }
    }
    .limitBox {
      margin-top: 30px;
      text-align: center;
      font-size: 39px;
      font-weight: bold;
      color: #0D0000;
      .limitBoxText {
        font-size: 14px;
        color: #A8A8A8;
        margin-top: 2px; 
      }
    }
    .btnBox {
      padding: 0 26px;
      margin-top: 28px;
      .btn {
        border: none;
        padding: 0;
        height: 58px;
        background: $linear-gradient;
        .btnText {
          font-size: 17px;
          font-weight: bold;
        }
        .btnTips {
          margin-top: 2px;
          font-size: 12px;
        }
      }
    }
  
    .checkedIcon {
      flex-shrink: 0;
      width: 14px;
      height: 14px;
      margin-right: 8px;
      margin-bottom: .5px;
    }
  
    .agreementBox {
      margin-top: 15px;
      padding: 0 30px;
      font-size: 12px;
      color: #8C8C8C;
      line-height: 16px;
      display: flex;
      justify-content: center;
  
      .checkedIcon {
        flex-shrink: 0;
        width: 14px;
        height: 14px;
        margin-right: 8px;
        margin-bottom: .5px;
      }
  
      .checked {
        flex-shrink: 0;
        vertical-align: text-bottom;
        margin-right: 8px;
        margin-bottom: .5px;
        border: 1px solid #D9D9D9;
        border-radius: 100%;
        width: 14px;
        height: 14px;
    
        &.active {
          position: relative;
          border: none;
          background: #FF3042;
    
          &::before {
            position: absolute;
            top: 4px;
            left: 3px;
            width: 9px;
            height: 6px;
            content: "";
            background-image: url("~@imgs/activity/check.png");
            background-size: 100%;
          }
        }
      }
  
      .agreementItem {
        color: #FF3042;
      }
    }
  }
}
