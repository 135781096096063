// 公共颜色
$color-primary: #FF4D4F;

:root {
  --color-primary: #{$color-primary}; // 默认主题色
  --color-primary-r: #{red($color-primary)};
  --color-primary-g: #{green($color-primary)};
  --color-primary-b: #{blue($color-primary)};
}

@function color-primary-fn($opacity: 1) {
  @return rgba(var(--color-primary-r), var(--color-primary-g), var(--color-primary-b), $opacity);
}

// 生成渐变色函数
@function linear-gradient-fn($color-primary) {
  $lighter-color: mix($color-primary, #fff, 50%); // 生成主题色减淡的颜色
  @return linear-gradient(108.61deg, $lighter-color 3.46%, $color-primary 97.1%);
}

// 通过 CSS 变量设置渐变色
:root {
  --linear-gradient: #{linear-gradient-fn($color-primary)};
}

// 主字色
$color-black: #262626;
$color-white: #FFFFFF;

// 浅红
$light-red: #FFF1F0;

// 浅红背景
$light-red-bg: #FFF3F4;

// 背景色
$background-color: #F6F6F6;

// 线条颜色
$line-color: #F0F0F0;

// 边框颜色
$border-color: #D9D9D9;

// 文字灰色颜色
$text-gray-color: #8C8C8C;

// 渐变色
// $linear-gradient: linear-gradient(108.61deg, #FF606E 3.46%, #FF192C 97.1%);
$linear-gradient: var(--linear-gradient);

// 占位符颜色
$placeholder-color: #BFBFBF;
