@import "@/styles/var.scss";
@import "@/styles/mixin.scss";

.apiToH5Skin {
  line-height: normal;
  background-color: #F5F6F8;
  .apiToH5Skin_header {
    width: 100%;
    height: 198px;
    background: linear-gradient(320deg, #FF9052 0%, #FF1C2F 100%);
    padding: 20px;
    border-radius: 0 0 18px 18px;
    display: flex;
    .apiToH5Skin_result_icon {
      width: 20px;
      height: 20px;
      margin-right: 7px;
      margin-top: 2px;
    }
    .apiToH5Skin_result_textBox {
      .apiToH5Skin_result_text1 {
        font-size: 17px;
        font-weight: bold;
        color: #fff;
      }
      .apiToH5Skin_result_text2 {
        margin-top: 3px;
        font-size: 13px;
        color: #fff;
      }
    }
  }
  .apiToH5Skin_body {
    margin-top: -120px;
    padding: 0 16px;
    .cardBox {
      width: 100%;
      background-color: #fff;
      border-radius: 8px;
      overflow: hidden;
      .cardBoxHeader {
        height: 40px;
        display: flex;
        align-items: center;
        position: relative;
        .cardBoxHeaderBg {
          width: 100%;
          height: 40rpx;
          position: absolute;
          left: 0;
          top: 0;
        }
        .cardBoxHeaderLeft {
          flex: 1;
          padding-left: 12px;
          position: relative;
          font-size: 15px;
          color: #6E1D12;
          font-weight: bold;
        }
        .cardBoxHeaderRight {
          flex: 1;
          padding-left: 12px;
          position: relative;
          display: flex;
          align-items: center;
          margin-top: 4px;
          .cardBoxHeaderRightIcon {
            width: 15px;
            height: 8px;
          }
          .cardBoxHeaderRightText {
            margin-left: 8px;
            font-size: 13px;
            color: #B3781A;
          }
        }
      }
      .cardBoxContent {
        padding: 20px;
        .companyBox {
          display: flex;
          align-items: center;
          .companyBoxLogo {
            width: 54px;
            height: 54px;
          }
          .companyBoxRight {
            flex: 1;
            margin-left: 19px;
            .companyBoxName {
              font-size: 14px;
              font-weight: bold;
              color: #000;
              .productNmae {
                font-size: 16px;
              }
            }
            .companyBoxOther {
              margin-top: 10px;
              display: flex;
              justify-content: space-between;
              .companyBoxOtherItem {
                text-align: center;
                .companyBoxOtherItemValue {
                  font-size: 16px;
                  font-weight: bold;
                  color: var(--color-primary);
                  .unit {
                    font-size: 12px;
                    font-weight: normal;
                  }
                }
                .companyBoxOtherItemLabel {
                  font-size: 12px;
                  color: #8C8C8C;
                }
              }
            }
          }
        }
        .otherInfoBox {
          margin-top: 24px;
          padding: 10px 15px;
          background-color: #FEF5EB;
          border-radius: 3px;
          .otherInfoItem {
            padding: 4px 0;
            display: flex;
            align-items: center;
            .ohterInfoItemLabel {
              font-size: 13px;
              font-weight: bold;
              color: #000;
            }
            .ohterInfoItemValue {
              font-size: 12px;
              color: #50515C;
            }
          }
        }
      }
    }
    .protocolBox {
      margin-top: 12px;
      padding: 18px 16px;
      border-radius: 8px;
      background-color: #fff;
      .protocolBoxContent {
        width: 311px;
        height: 160px;
        border-radius: 8px;
        // background: #F2F5FD;
        box-sizing: border-box;
        overflow-y: auto;
        .protocolBoxTitle {
          color: #262626;
          text-align: center;
          font-size: 15px;
          font-weight: bold;
          margin-bottom: 12px;
        }
        p {
          color: #50515C;
          font-size: 12px;
          line-height: 24px;
        }
      }
    }
    .specialTips {
      margin-top: 12px;
      line-height: 20px;
      .specialTipsTag {
        display: inline-block;
        padding: 2px 8px;
        background: linear-gradient(320deg, #FF9052 0%, #FF1C2F 100%);
        border-radius: 4px 4px 4px 4px;
        font-size: 12px;
        color: #fff;
        margin-right: 5px;
        line-height: normal;
        transform: scale(.85);
      }
      .specialTipsContent {
        color: #50515C;
        font-size: 12px;
      }
    }
  }
}
